import React from "react"

import Image from "../components/image"
import Badge from "../components/badge"
import BACP from "../components/bacp"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="container mx-auto flex px-5 lg:py-24 md:flex-row flex-col items-center">
      <div className="w-1/3 mb-10 md:mb-0">
        <Image />
      </div>
      <div className="lg:flex-grow md:w-2/3 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
          Counselling,
          <br /> Psychotherapy &amp; Supervision
        </h1>
        <p>Hello, and welcome to my website.</p>
        <p>
          As a fully qualified professional counsellor Accredited by the BACP, I
          aim to respect you as an individual with autonomy. As such everything
          we do is based upon what is important to you as a person, from your
          own perspective. I believe that you know what hurts and that you have
          the capacity to begin a healing process. I hope that I am able to
          offer you a safe environment in which to explore any issue that is
          important to you.
        </p>
        <p>
          I provide counselling from home in Accrington, Lancashire. If you
          would like to make an appointment please view the contact me page,
          where you will find my contact details.
        </p>
        <p>
          Margaret Heap, B A (Hons); P G Dip; Dip Sup: Registered MBACP
          (Accred).
        </p>

        <div class="flex items-center">
          <p class="mr-8">
            <BACP />
          </p>
          <p>
            <Badge />
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
